var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _vm.sendingRequest
        ? _c("LoaderLogo")
        : [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c("font-awesome-icon", {
                  staticClass: "mr-2",
                  attrs: { icon: ["fad", "users"] },
                }),
                _c("h2", [_vm._v("Edit External Users & Associations")]),
                _c(
                  "base-button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Close",
                        expression: "'Close'",
                      },
                    ],
                    staticClass:
                      "search-wrapper-button btn-gradient condensed ml-auto btn-secondary",
                    attrs: { handleClick: _vm.forceClose },
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "icon icon-only",
                      attrs: { icon: ["fas", "times"] },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "body" }, [
              _c(
                "div",
                { staticClass: "add-user" },
                [
                  _c("AddExternalUserAssociation", {
                    attrs: {
                      orgId: _vm.orgId,
                      getEditExternalUsersEmails:
                        _vm.getEditExternalUsersEmails,
                    },
                    on: { addNewExtUser: _vm.addNewExtUser },
                  }),
                ],
                1
              ),
              _c("hr", { staticClass: "my-4" }),
              _c("div", { staticClass: "external-users-header" }, [
                _c("h4", [
                  _vm._v("Edit External User Application Associations"),
                ]),
                _c("div", { staticClass: "ml-auto d-flex" }, [
                  _c("div", [_vm._v("Customise Permissions")]),
                  _c("div", { staticClass: "toggle-btn" }, [
                    _c("label", { staticClass: "switch" }, [
                      _c("input", {
                        attrs: { type: "checkbox" },
                        on: { click: _vm.toggleAdvancedEdit },
                      }),
                      _c("span", { staticClass: "slider round" }),
                    ]),
                  ]),
                ]),
              ]),
              _vm.showContactSupportError
                ? _c("div", { staticClass: "error" }, [
                    _c("p", [
                      _vm._v(
                        "Something is not right. Please contact support. Thank you!"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "user-cards" },
                _vm._l(_vm.editExternalUsers, function (user) {
                  return _c(
                    "div",
                    { key: user.email, staticClass: "d-flex just user-card" },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                boundariesElement: "body",
                                content:
                                  user.firstname +
                                  " " +
                                  user.surname +
                                  ", " +
                                  user.email,
                              },
                              expression:
                                "{\n                boundariesElement: 'body',\n                content: `${user.firstname} ${user.surname}, ${user.email}`,\n              }",
                            },
                          ],
                          staticClass: "flex-user p-2",
                        },
                        [
                          _c(
                            "span",
                            [
                              _c("font-awesome-icon", {
                                staticClass: "icon mr-1 ml-0",
                                attrs: { icon: ["fas", "user"] },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "ellipsis" }, [
                            _vm._v(
                              " " +
                                _vm._s(user.firstname) +
                                " " +
                                _vm._s(user.surname) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(" " + _vm._s(user.email) + " "),
                          ]),
                        ]
                      ),
                      _vm._l(_vm.orgAppList, function (app) {
                        return _c(
                          "div",
                          { key: app.id, staticClass: "flex-app" },
                          [
                            _vm.isAppSelected(user.appRoleActions, app.id)
                              ? [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: {
                                            boundariesElement: "body",
                                            content: app.name,
                                          },
                                          expression:
                                            "{ boundariesElement: 'body', content: app.name }",
                                        },
                                      ],
                                      staticClass: "application-wrapper",
                                      style: {
                                        backgroundColor: app.color || "#000000",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleUserAssociation(
                                            user.email,
                                            app.id,
                                            "remove"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "check"] },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: {
                                            boundariesElement: "body",
                                            content: app.name,
                                          },
                                          expression:
                                            "{ boundariesElement: 'body', content: app.name }",
                                        },
                                      ],
                                      staticClass:
                                        "application-wrapper bg-opaque",
                                      style: {
                                        backgroundColor: app.color || "#000000",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleUserAssociation(
                                            user.email,
                                            app.id,
                                            "add"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(app.abbreviation) + " "
                                      ),
                                    ]
                                  ),
                                ],
                            _vm.advancedEditSelected
                              ? _c(
                                  "div",
                                  { staticClass: "custom-roles" },
                                  _vm._l(
                                    _vm.getRolesForApp(app.id),
                                    function (role) {
                                      return _c(
                                        "div",
                                        { key: app.id + "-" + role.id },
                                        [
                                          _c(
                                            "button",
                                            {
                                              key: role.id,
                                              staticClass:
                                                "search-wrapper-button btn-gradient condensed ml-auto btn-role-choice",
                                              class: {
                                                "btn-role-choice-chosen":
                                                  !_vm.isRoleSelected(
                                                    user.appRoleActions,
                                                    app.id,
                                                    role.id
                                                  ),
                                              },
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.toggleUserAssociation(
                                                    user.email,
                                                    app.id,
                                                    _vm.isRoleSelected(
                                                      user.appRoleActions,
                                                      app.id,
                                                      role.id
                                                    )
                                                      ? "remove"
                                                      : "add",
                                                    role.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.toTitleCase(role.name)
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.showEmptyAppWarning &&
                      _vm.checkForEmptyAppAssociations.length > 0,
                    expression:
                      "showEmptyAppWarning && checkForEmptyAppAssociations.length > 0",
                  },
                ],
                staticClass: "warning",
              },
              [
                _c("h3", [
                  _vm._v(
                    "Some newly added users have no App access associated to them"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " The affected users are shown below. Please update these users before resubmitting "
                  ),
                ]),
                _vm._l(_vm.checkForEmptyAppAssociations, function (user) {
                  return _c("span", { key: user.email }, [
                    _vm._v(_vm._s(user.firstname) + " " + _vm._s(user.surname)),
                  ])
                }),
              ],
              2
            ),
            _c("div", { staticClass: "actions" }, [
              _c(
                "div",
                [
                  _c(
                    "base-button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Update User Associations",
                          expression: "'Update User Associations'",
                        },
                      ],
                      staticClass:
                        "search-wrapper-button btn-gradient condensed",
                      attrs: {
                        disabled: _vm.dirtyUsers.length === 0,
                        handleClick: _vm.updateExtUsers,
                      },
                    },
                    [
                      _vm._v(" Update User Associations "),
                      _c("font-awesome-icon", {
                        staticClass: "icon icon-only ml-2",
                        attrs: { icon: ["fad", "save"] },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "ml-auto d-flex" }, [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "base-button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Revert all changes",
                            expression: "'Revert all changes'",
                          },
                        ],
                        staticClass:
                          "search-wrapper-button btn-gradient condensed btn-secondary",
                        attrs: {
                          handleClick: _vm.revert,
                          disabled: _vm.dirtyUsers.length === 0,
                        },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon icon-only",
                          attrs: { icon: ["fas", "undo"] },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "ml-2" },
                  [
                    _c(
                      "base-button",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Close",
                            expression: "'Close'",
                          },
                        ],
                        staticClass:
                          "search-wrapper-button btn-gradient condensed btn-secondary",
                        attrs: { handleClick: _vm.forceClose },
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon icon-only",
                          attrs: { icon: ["fas", "times"] },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Application from '@/components/Application.vue';
import ApplicationType from '@/models/Application';
import BaseButton from '@/components/base/BaseButton.vue';
import LoaderLogo from '@/components/LoaderLogo.vue';
import GraphApi from '@/api/graphApi';

@Component({
    components: { Application, BaseButton, LoaderLogo },
})
export default class EditAppsSidebar extends Vue {
    @Prop() orgAppList!: ApplicationType[];

    @Prop() orgId!: string;

    @Prop(Boolean) showLoading!: boolean;

    public appListSelection: ApplicationType[] = [];

    public sendingRequest = false;

    showPopup = false;

    mounted () {
        this.appListSelection = JSON.parse(JSON.stringify(this.orgAppList));
    }

    get applicationsList (): ApplicationType[] {
        return this.$store.state.applications;
    }

    get selectedAppsIds (): string[] {
        return this.appListSelection.map((app) => app.id);
    }

    public addSelectedApp ($event: any, selectedApp: ApplicationType): void {
        const currentIndex: number = this.appListSelection.findIndex(
            (app: ApplicationType) => app.id === selectedApp.id,
        );
        if (currentIndex < 0) {
            this.appListSelection.push(selectedApp);
        } else {
            Vue.delete(this.appListSelection, currentIndex);
        }
    }

    public deselectApps () {
        this.appListSelection = [];
    }

    showPopupToConfirm () {
        this.showPopup = true;
    }

    confirmDeactivate () {
        this.showPopup = false;
        this.deselectApps();
    }

    cancelDeactivate () {
        this.showPopup = false;
    }

    public async updateAppRequest (): Promise<void> {
        this.sendingRequest = true;
        await GraphApi.updateOrgApps(this.orgId, this.appListSelection);
        this.$emit('requestLatestData');
    }

    public forceClose (): void {
        this.$store.commit('toggleSideBar', false);
        this.$store.dispatch('portalInView', '');
        this.showLoading = false;
    }
}











































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import BaseButton from '@/components/base/BaseButton.vue';
import LoaderLogo from '@/components/LoaderLogo.vue';
import AddExternalUserAssociation from '@/components/AddExternalUserAssociation.vue';
import ExternalUserWithDelete from '@/components/ExternalUserWithDelete.vue';
import DbUser from '@/models/DbUser';
import { RoleActions, DbUserUpdate, AppRoleActions } from '@/models/DbUserUpdate';
import Role from '@/models/Role';
import GraphApi from '@/api/graphApi';

@Component({
    components: {
        BaseButton,
        LoaderLogo,
        AddExternalUserAssociation,
        ExternalUserWithDelete,
    },
})
export default class EditExternalUsersMatrixSidebar extends Vue {
    @Prop() orgId!: string;

    @Prop() orgAppList!: any;

    @Prop() allRolesByApp!: { appId: string; roles : Role[] }[];

    public selected = false;

    public sendingRequest = false;

    public staticExternalUsers: DbUser[] = [];

    public editExternalUsers: DbUserUpdate[] = [];

    public advancedEditSelected = false;

    showContactSupportError = false;

    get getEditExternalUsersEmails (): string[] {
        return this.editExternalUsers.map((user) => user.email);
    }

    created (): void {
        this.requestLatestData();
    }

    addNewExtUser ({
        email,
        firstname,
        surname,
    }: {
        email: string;
        firstname: string;
        surname: string;
    }): void {
        const user: DbUserUpdate = {
            appRoleActions: this.getEmptyAppsRoles(),
            email,
            firstname,
            surname,
        };
        this.editExternalUsers.unshift(user);
    }

    toggleAdvancedEdit () : void {
        this.advancedEditSelected = !this.advancedEditSelected;
    }

    isAppSelected (uApps: AppRoleActions[], appId: string) {
        const app = uApps.find((uApp) => uApp.appId === appId);
        if (app) {
            const result = !app.roleActions.every((roleAction) => roleAction.action === 'remove');
            return result;
        }
        return false;
    }

    isRoleSelected (uApps: AppRoleActions[], appId: string, roleId: string) {
        const app = uApps.find((uApp) => uApp.appId === appId);
        if (app) {
            const role = app.roleActions.find((roleAction) => roleAction.roleId === roleId);
            return role;
        }
        return false;
    }

    toggleUserAssociation (
        userEmail: string,
        appId: string,
        action: string,
        roleId?: string,
    ): void {
        this.showContactSupportError = false;
        const userIndex = this.getUserIndex(userEmail);
        const appIndex = this.getUserApp(userIndex, appId);

        const roleIdToEdit = roleId ?? this.getDefaultRole(appId);
        const roleIndex = this.getRoleIndex(userIndex, appIndex, roleIdToEdit);

        const userAndAppIndexesExist = userIndex !== -1 && appIndex !== -1;

        if (!userAndAppIndexesExist) {
            this.showContactSupportError = true;
            return;
        }
        if (action === 'add') {
            if (!this.editExternalUsers[userIndex].appRoleActions[appIndex].roleActions[roleIndex]) {
                this.editExternalUsers[userIndex].appRoleActions[appIndex].roleActions.push({
                    roleId: roleIdToEdit,
                    action: 'add',
                });
            } else {
                this.editExternalUsers[userIndex].appRoleActions[appIndex].roleActions.splice(roleIndex, 1, {
                    roleId: roleIdToEdit,
                    action: '',
                });
            }
        } else if (roleId) {
            this.editExternalUsers[userIndex].appRoleActions[appIndex].roleActions.splice(roleIndex, 1);
        } else {
            this.editExternalUsers[userIndex].appRoleActions[appIndex].roleActions = [];
        }
    }

    getUserIndex (email: string): number {
        return this.editExternalUsers.findIndex((user) => user.email === email);
    }

    getUserApp (userIndex: number, appToGet: string): number {
        return this.editExternalUsers[userIndex].appRoleActions.findIndex(
            (app) => app.appId === appToGet,
        );
    }

    getRolesForApp (appId: string): Role[] {
        const appRoleObj = this.allRolesByApp.find((a) => a.appId === appId);
        if (appRoleObj === undefined) return [];
        return appRoleObj.roles;
    }

    public async requestLatestData (): Promise<void> {
        this.sendingRequest = true;
        const response: any = await GraphApi.getUserAssociations({
            OrgId: this.orgId,
        });

        const latestUsersAssoc: DbUser[] = response.data;
        this.staticExternalUsers = JSON.parse(JSON.stringify(latestUsersAssoc));
        const latestUsers : DbUser[] = JSON.parse(JSON.stringify(latestUsersAssoc));

        this.editExternalUsers = latestUsers.map((user) => ({
            email: user.email,
            firstname: user.firstname,
            surname: user.surname,
            appRoleActions: user.appRoles.map((app) => ({ appId: app.appId, roleActions: app.roles.map((role) => ({ roleId: role.id, action: '' })) })),
        }),
        );

        this.sendingRequest = false;
    }

    public forceClose (): void {
        this.$store.commit('toggleSideBar', false);
        this.$store.dispatch('portalInView', '');

        this.sendingRequest = false;
    }

    // TODO: need API
    public updateSendingRequest (value: boolean) {
        this.sendingRequest = value;
    }

    // TODO: need API to send dirty users to

    // #region update external users

    showEmptyAppWarning = false;

    get dirtyUsers (): DbUserUpdate[] {
        this.showContactSupportError = false;
        const dirtyUsers: DbUserUpdate[] = [];
        const dirtyEditedUsers = JSON.parse(JSON.stringify(this.editExternalUsers));

        dirtyEditedUsers.forEach((eUser: DbUserUpdate) => {
            const sUserIndex = this.staticExternalUsers.findIndex(
                (sUser) => sUser.email === eUser.email,
            );

            if (sUserIndex === -1) {
                // if user is new, create app & 'add'
                eUser.appRoleActions = eUser.appRoleActions.map((ar) => ({
                    appId: ar.appId,
                    roleActions: ar.roleActions.map((r) => ({ roleId: r.roleId, action: 'add' })),
                }));
                dirtyUsers.push(eUser);
            } else {
                // user already exists
                let dirty = false;
                const staticAppRoles = this.staticExternalUsers[sUserIndex].appRoles;

                // if application is newly selected
                eUser.appRoleActions.forEach((eApp) => {
                    const sAppRoleIndex = staticAppRoles.findIndex((sApp) => sApp.appId === eApp.appId);
                    if (sAppRoleIndex === -1) {
                        this.showContactSupportError = true;
                        return;
                    }

                    eApp.roleActions.forEach((eRole) => {
                        if (staticAppRoles[sAppRoleIndex].roles.findIndex((sRole) => eRole.roleId === sRole.id) === -1) {
                            eRole.action = 'add';
                            dirty = true;
                        }
                    });
                });

                // if app is on db, flag as 'remove'
                staticAppRoles.forEach((sApp) => {
                    const eAppRoleIndex = eUser.appRoleActions.findIndex((eApp) => eApp.appId === sApp.appId);
                    if (eAppRoleIndex === -1) {
                        this.showContactSupportError = true;
                        return;
                    }

                    sApp.roles.forEach((sRole) => {
                        if (eUser.appRoleActions[eAppRoleIndex].roleActions.findIndex((eRole) => eRole.roleId === sRole.id) === -1) {
                            eUser.appRoleActions[eAppRoleIndex].roleActions.push({ action: 'remove', roleId: sRole.id });
                            dirty = true;
                        }
                    });
                });

                // push touched entries
                if (dirty) dirtyUsers.push(eUser);
            }
        });

        const dirtyUsersActionsOnly: DbUserUpdate[] = [];
        dirtyUsers.forEach((user) => {
            const appRoleActions: DbUserUpdate['appRoleActions'] = [];
            user.appRoleActions.forEach((appRoleAction) => {
                const appId = appRoleAction.appId;
                const roleActions = appRoleAction.roleActions.filter((r) => r.action !== '');
                if (!(roleActions.length === 0)) {
                    appRoleActions.push({ appId, roleActions }); // so only apps with a role action will be added
                }
            });

            const dbUserUpdate = {
                email: user.email,
                firstname: user.firstname,
                surname: user.surname,
                appRoleActions,
            };

            dirtyUsersActionsOnly.push(dbUserUpdate);
        });
        return dirtyUsersActionsOnly;
    }

    get checkForEmptyAppAssociations (): Array<DbUserUpdate> {
        const foundUser = this.dirtyUsers.filter((user: DbUserUpdate) => user.appRoleActions.every((ara) => ara.roleActions.length < 1));

        return foundUser;
    }

    async updateExtUsers (ignoreEmptyApps = false): Promise<void> {
        console.log(this.dirtyUsers);
        this.showEmptyAppWarning = false;

        const dirtyUsersHasEmptyAppAssociations = this.checkForEmptyAppAssociations;

        if (
            dirtyUsersHasEmptyAppAssociations.length > 0
      && ignoreEmptyApps === false
        ) {
            this.showEmptyAppWarning = true;
            return;
        }

        // return false;
        this.sendingRequest = true;
        const response: any = await GraphApi.updateUserAssociations({
            OrgId: this.orgId,
            users: this.dirtyUsers,
        });

        console.log(response);
        this.requestLatestData();
        // this.sendingRequest = false;
        // 1. send dirtyUsers to API
    }

    getRoleIndex (userIndex: number, appIndex : number, roleIdToGet: string): number {
        return this.editExternalUsers[userIndex].appRoleActions[appIndex].roleActions.findIndex(
            (role) => role.roleId === roleIdToGet,
        );
    }

    getDefaultRole (appId: string) {
        const rolesForApp = this.allRolesByApp.find((ar) => ar.appId === appId);
        if (rolesForApp) {
            const defaultRole = rolesForApp.roles.find((r) => r.isDefault);
            if (defaultRole) return defaultRole.id;
        }

        return '';
    }

    getEmptyAppsRoles () : AppRoleActions[] {
        const appRoleActions : { appId: string; roleActions : {roleId : string; action : string}[] }[] = [];
        this.allRolesByApp.forEach((ar) => {
            const appId = ar.appId;
            const roleActions : RoleActions[] = [];
            appRoleActions.push({ appId, roleActions });
        });
        return appRoleActions;
    }

    revert () {
        this.showEmptyAppWarning = false;

        this.editExternalUsers = JSON.parse(
            JSON.stringify(this.staticExternalUsers),
        );
    }

    toTitleCase (input: string): string {
        return input.replace(/\w\S*/g, (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase());
    }
}

export default class Application {
    public id!: string;

    public name!: string;

    public color!: string;

    public abbreviation!: string;

    public schemeFlag!: boolean;

    public dependents?: string[] | null;

    constructor (
        id: string,
        name: string,
        color: string,
        abbreviation: string,
        dependents: string[] | null,
        schemeFlag: boolean,
    ) {
        this.id = id;
        this.name = name;
        this.color = color;
        this.abbreviation = abbreviation;
        this.dependents = dependents;
        this.schemeFlag = schemeFlag;
    }
}
